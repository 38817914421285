import AdsBanner from "../../app-layouts/AdsBanner";
import AppLayout from "../../app-layouts/AppLayout";
import HomePageSideNav from "../../app-components/HomeSideNav";
import { Link } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo } from "react";
import { APP_STORAGE } from "../../api-services/process";
import Preloader from "../../app-components/preloader/Preloader";
import { useState } from "react";
import { LuUsers2 } from "react-icons/lu";
import { Loading } from "../../assets/img";
import { TERipple } from "tw-elements-react";
import { getUsersRequests } from "../../api-services/api-query/connection-requests/useUserRequests";
import apiClient from "../../api-services/ApiClient";
import { successAlert } from "../../app-util/SweetAlert";

const MyRequests = () => {
  const { ref, inView } = useInView();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [loading, setLoading] = useState(false);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["my_requests"],
    queryFn: getUsersRequests,
    initialPageParam: 1,
    getNextPageParam: (data) =>
      data.currentPage < data.totalPages ? data.currentPage + 1 : undefined,
  });

  const getImageSrc = (post: any) => {
    if (post?.caption_image)
      return `${APP_STORAGE}gallery_pictures/${post.caption_image}`;
    else if (post?.user?.profile_picture)
      return `${APP_STORAGE}${post.user.profile_picture}`;
    return "img/PrivateAccount.png";
  };

  const isVideo = (post: any): boolean => {
    // Define a list of common video file extensions
    const videoExtensions = [
      ".mp4",
      ".mov",
      ".avi",
      ".mkv",
      ".webm",
      ".flv",
      ".wmv",
    ];

    // Extract the file extension in lowercase
    const fileExtension = post?.caption_image
      ?.toLowerCase()
      .slice(post?.caption_image.lastIndexOf("."));

    // Check if the file extension is in the list of video extensions
    return videoExtensions.includes(fileExtension);
  };

  const connectionComponent = useMemo(() => {
    if (!data || data.pages.length === 0 || data.pages[0].data.length === 0) {
      return (
        <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 mt-[25vh]">
          <p className="mb-5 text-sm">No history available</p>
          <Link
            className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
            to={SITELINKS.MAKE_REQUEST}
          >
            Make a request
          </Link>
        </div>
      );
    }

    return data.pages.flatMap((page) =>
      page.data.map((post: any, index: number) => (
        <div
          key={post.uuid}
          ref={index === 5 ? ref : undefined}
          className="flex gap-5 bg-white dark:bg-slate-800 lg:rounded-10px sm:mb-0 p-2 py-3 mx-2 rounded-10px p-3.5 items-center"
        >
          <div className="overflow-hidden w-[80px] max-h-[80px] overflow-hidden rounded-xl">
            {isVideo(post) ? (
              <video
                muted
                controls
                tabIndex={0}
                onFocus={(e) => {
                  console.log("Video focused");
                  e.currentTarget.play();
                }}
                onBlur={(e) => {
                  console.log("Video blurred");
                  e.currentTarget.pause();
                }}
                className="w-full rounded-xl bg-gray-100 dark:bg-slate-700 object-cover"
              >
                <source
                  src={`${APP_STORAGE}gallery_videos/${post.caption_image}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={getImageSrc(post)}
                alt="Display"
                className="w-full rounded-xl bg-gray-100 dark:border-slate-700 border"
              />
            )}
          </div>

          <div className="justify-between gap-2 items-center">
            {/* connect interest */}
            <div className="flex gap-2 w-full">
              <p className="text-sm flex gap-2 font-semibold dark:text-gray-300">
                <LuUsers2 className="text-xl" /> {post?.interest}
                <span className="bg-gray-100 dark:bg-slate-800 border dark:text-gray-300 text-xs flex items-center font-semibold rounded-full px-2">
                  {post?.response_count} of {post?.expected_response}
                </span>
              </p>
            </div>

            <div className="flex gap-2 w-full mt-1 dark:text-gray-400">
              <p className="text-xs flex gap-2 font-normal">
                {post?.prespecified_text}
              </p>
            </div>

            <div className="flex gap-2 w-full dark:text-gray-400">
              <p className="text-xs flex gap-2 font-normal items-center">
                {post?.location?.city}, {post?.location?.state},{" "}
                {post?.location?.country}
              </p>
            </div>

            <div className="flex gap-2 mt-2">
              <Link
                to={`${SITELINKS.REQUEST_HISTORY}/${post.uuid}`}
                className="font-medium text-sm text-honeConnectPurple dark:text-gray-600 bg-purple-100 px-2 rounded-full"
              >
                View
              </Link>

              {post?.status === "pending" ? (
                <button
                  onClick={() => closeRequest(post.uuid)}
                  className="font-medium text-sm text-red-600  dark:text-red-400 bg-red-100 px-2 rounded-full"
                >
                  Close
                </button>
              ) : (
                post?.response_count < post?.expected_response && (
                  <button
                    onClick={() => reopenRequest(post.uuid)}
                    className="font-medium text-sm text-honeConnectOrange dark:text-gray-800 bg-pink-100 px-2 rounded-full"
                  >
                    Reopen
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      ))
    );
    // eslint-disable-next-line
  }, [data, ref]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  if (isLoading) {
    return (
      <AppLayout title="Home">
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  const closeRequest = async (id: string) => {
    try {
      setLoading(true);
      await apiClient.patch(`close-request/${id}`);
      successAlert();
      refetch();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const reopenRequest = async (id: string) => {
    try {
      setLoading(true);
      await apiClient.patch(`reopen-request/${id}`);
      successAlert();
      refetch();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout title="My Request">
      <div className="grid grid-cols-12 gap-8 justify-between">
        <HomePageSideNav />

        <div className="flex bg-hc_F4EBFA min-h-[100vh] dark:bg-slate-700 border-b border-gray-100 dark:border-slate-800 col-span-12 sm:col-span-7 w-full">
          <div className="flex flex-col w-full">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-28 mb-28 w-full">
              <div
                className={`shadow flex justify-between w-full bg-white dark:bg-slate-800 border-b dark:border-slate-600 z-10 fixed transition-all duration-500 top-0 ${
                  scrollDirection === null
                    ? "translate-y-[57px]"
                    : scrollDirection === "up"
                    ? "translate-y-0 top-[57px]"
                    : "-translate-y-full"
                }`}
              >
                <TERipple rippleColor="#aaa">
                  <button className="py-3 lg:relative lg:rounded-10px font-medium lg:text-lg lg:p-3 px-4 flex gap-3 items-center">
                    <LuUsers2
                      style={{ fill: "#d4b1e6" }}
                      className="fa-solid fa-users text-gray-600 dark:text-gray-300 text-2xl"
                    />
                    <p className="text-honeConnectBlack dark:text-gray-300">
                      {`${data?.pages[0]?.totalItems ?? "0"} Request${
                        data?.pages[0]?.totalItems > 1 ? "s" : ""
                      }`}
                    </p>
                  </button>
                </TERipple>
              </div>

              {connectionComponent}

              {isFetchingNextPage && (
                <div className="flex py-5 pb-16 justify-center">
                  <img
                    src={Loading}
                    alt="loader"
                    className="h-[20px] w-[20px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <AdsBanner />
      </div>

      <Preloader loading={loading} />
    </AppLayout>
  );
};

export default MyRequests;
