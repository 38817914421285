// Site link declaration
export enum SITELINKS {
  LOGIN = "/login",
  GOOGLE_LOGIN = "/auth/google/callback",
  REGISTER = "/register",
  REGISTER_STEP_TWO = "/register-step-two",
  REGISTER_STEP_THREE = "/register-step-three",
  HOME = "/home",
  VERIFICATION = "/verify-code",
  UPDATE_PASSWORD = "/update-password",

  ADD_PROFILE = "/add-profile",
  EDIT_PROFILE = "/update-profile",
  MY_PROFILE = "/me",
  PROFILE_VIEW = "/profile",

  ADD_INTEREST = "/add-interest",
  MAKE_REQUEST = "/make-new-request",
  REQUEST_REQUESTER = "/request-requester",
  MY_REQUESTS = "/my-requests",
  REQUEST_HISTORY = "/request-history",
  FORGOT_PASSWORD = "/forgot-password",
  SUBSCRIPTIONS = "/subscriptions",
  MY_RESPONSES = "/my-responses",
  MY_PAYMENTS = "/my-payments",
  PRIVATE_ACCOUNT = "/private-account",
  NOTIFICATIONS = "/notifications",
  SETTINGS = "/settings",
  CHAT = "/messaging",
  CHATIN = "/chat",
}
