import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ReactNode } from "react";
import useAuth from "./useAuth";
import Preloader from "../app-components/preloader/Preloader";
import { SITELINKS } from "./links";

// view- pages
import Login from "../pages/auth/Login";
import RegisterStepOne from "../pages/auth/RegisterStepOne";
import RegisterStepTwo from "../pages/auth/RegisterStepTwo";
import RegisterStepThree from "../pages/auth/RegisterStepThree";
import UpdatePassword from "../pages/auth/UpdatePassword";
import VerifyEmail from "../pages/auth/VerifyEmail";

import ForgotPassword from "../pages/auth/ForgotPassword";
import VerificationPage from "../pages/auth/VerificationPage";
import HomePage from "../pages/home/HomePage";
import MyRequests from "../pages/request/MyRequests";
import Profile from "../pages/profile/Profile";
import MyResponses from "../pages/request/MyResponses";
import Settings from "../pages/settings/Settings";
import Subscriptions from "../pages/subscription/Subscriptions";
import MakeNewRequest from "../pages/request/MakeNewRequest";
import Notifications from "../pages/notification/Notification";
import WebsiteIndex from "../pages/website/WebsiteIndex";
import GoogleCallback from "../pages/auth/GoogleCallback";
import { getUser } from "../api-services/process";
import EditProfile from "../pages/profile/EditProfile";
import Messaging from "../pages/messaging/messaging";
import MyRequestMembers from "../pages/request/MyRequestmembers";
import TermsOfUse from "../pages/website/TermsAndConditions";
import PrivacyPolicy from "../pages/website/PrivacyPolicy";
import MyPayments from "../pages/payment/MyPayments";

// Middleware to validate token
function ProtectedRoute({ children }: { children: ReactNode }) {
  const { isAuthenticated, loading } = useAuth();
  const user = getUser();

  // Show preloader while loading
  if (loading) {
    return (
      <div>
        <Preloader loading={loading} />
      </div>
    );
  }

  if (isAuthenticated) {
    if (!user?.username || !user?.DOB || !user?.country_id) {
      return <Navigate to={SITELINKS.REGISTER_STEP_TWO} replace />;
    }
  } else {
    localStorage.clear();
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={SITELINKS.LOGIN} replace />
  );
}

function ProtectedAuthRoute({ children }: { children: ReactNode }) {
  const { isAuthenticated, loading } = useAuth();

  // Show preloader while loading
  if (loading) {
    return (
      <div>
        <Preloader loading={loading} />
      </div>
    );
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={SITELINKS.LOGIN} replace />
  );
}

// config routes
function APPRoutes() {
  return (
    <Router>
      <Routes>
        {/* public routes */}
        <Route path="/" element={<WebsiteIndex />} />
        <Route path="/terms-and-conditions" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />



        <Route path="/verify/:email/:code" element={<VerifyEmail />} />
        <Route path={SITELINKS.LOGIN} element={<Login />} />
        <Route path={SITELINKS.GOOGLE_LOGIN} element={<GoogleCallback />} />
        <Route path={SITELINKS.REGISTER} element={<RegisterStepOne />} />
        <Route path={SITELINKS.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={SITELINKS.VERIFICATION} element={<VerificationPage />} />

        {/* protected auth */}
        <Route
          path={SITELINKS.REGISTER_STEP_TWO}
          element={
            <ProtectedAuthRoute>
              <RegisterStepTwo />
            </ProtectedAuthRoute>
          }
        />

        <Route
          path={SITELINKS.REGISTER_STEP_THREE}
          element={
            <ProtectedAuthRoute>
              <RegisterStepThree />
            </ProtectedAuthRoute>
          }
        />

        <Route
          path={SITELINKS.UPDATE_PASSWORD}
          element={
            <ProtectedAuthRoute>
              <UpdatePassword />
            </ProtectedAuthRoute>
          }
        />

        <Route
          path={SITELINKS.HOME}
          element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.MAKE_REQUEST}
          element={
            <ProtectedRoute>
              <MakeNewRequest />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.MY_REQUESTS}
          element={
            <ProtectedRoute>
              <MyRequests />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.NOTIFICATIONS}
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.SUBSCRIPTIONS}
          element={
            <ProtectedRoute>
              <Subscriptions />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${SITELINKS.SUBSCRIPTIONS}/:id`}
          element={
            <ProtectedRoute>
              <Subscriptions />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${SITELINKS.MY_PROFILE}/:id`}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.EDIT_PROFILE}
          element={
            <ProtectedRoute>
              <EditProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.SETTINGS}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.MY_RESPONSES}
          element={
            <ProtectedRoute>
              <MyResponses />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.MY_PAYMENTS}
          element={
            <ProtectedRoute>
              <MyPayments />
            </ProtectedRoute>
          }
        />

        <Route
          path={SITELINKS.CHAT}
          element={
            <ProtectedRoute>
              <Messaging />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${SITELINKS.CHATIN}`}
          element={
            <ProtectedRoute>
              <Messaging />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${SITELINKS.REQUEST_HISTORY}/:id`}
          element={
            <ProtectedRoute>
              <MyRequestMembers />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default APPRoutes;
