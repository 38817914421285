import WebsiteHeader from "./website-components/WebsiteHeader";
import WebsiteFooter from "./website-components/WebsiteFooter";

const TermsOfUse = () => {
  return (
    <main>
      <WebsiteHeader />
      {/* HERO SECTION */}
      <div className="bg-[#F8FAFC]" id="home">
        <div className="w-11/12 mx-auto py-10 md:py-16 md:pb-16 grid md:grid-rows-2 lg:grid-rows-none lg:grid-cols-2 gap-10">
          <div className="pr-0 lg:pr-20  lg:text-left  dark:text-gray-600">
            <h1 className="text-[30px] md:text-[55px] font-bold mb-5 md:mb-8 lg:mb-4 leading-tight text-left">
              Terms <span className="text-honeConnectPurple"> </span> And{" "}
              <span className="text-honeConnectPurple">Conditions</span>{" "}
            </h1>

            <div className="flex flex-col dark:text-gray-600 gap-3 text-sm text-slate-700">
              <h1 className="font-medium text-lg">1.0 Introduction</h1>
              <p>
                Hone Connect Ltd, a private company limited by shares,
                incorporated in the Federal Republic of Nigeria with
                registration number 7570392.
              </p>
              <p>
                By accessing or using Hone Connect Ltd's Services, you agree to
                be bound by this Terms of Use Agreement including our Privacy
                Policy, Safety Tips, or other supplementary terms, so it is
                important that you read this Agreement and these policies
                carefully before you create an account.
              </p>
              <p>
                By accessing or using our Services on{" "}
                <a href="https://honeconnect.com">honeconnect.com</a>, the Hone
                Connect mobile application, or any other platforms or services
                Hone Connect may offer, you agree to, and are bound by this
                Agreement. This Agreement applies to anyone who accesses or uses
                our Services, regardless of registration or subscription status.
              </p>
              <p>
                If you do not agree with any part of these Terms, or if you are
                not eligible or authorized to be bound by the Terms, then do not
                access or use the Service.
              </p>
              <p>
                We reserve the right to modify, amend, or change the Terms at
                any time. We may notify you of a change to the Terms via email
                or other means; however, you are responsible for regularly
                checking this page for any changes. Your continued access or use
                of our Services constitutes your ongoing consent to any changes,
                and as a result, you will be legally bound by the updated Terms.
                If you do not accept a change to the Terms, you must stop
                accessing or using our Services immediately.
              </p>

              <h1 className="font-medium text-lg pt-10">2.0 Definitions</h1>
              <ul className="flex flex-col gap-3">
                <li>
                  <strong>2.1 Individual:</strong> Means a person (i.e., not a
                  corporate body, partnership, or business entity).
                </li>
                <li>
                  <strong>2.2 Users:</strong> Means individuals who use Hone
                  Connect Ltd. services.
                </li>
                <li>
                  <strong>2.3 Content:</strong> Means data uploaded by a user
                  including but not limited to; contact information, location
                  information, demographic information, multimedia (images,
                  audio recordings, video recordings, etc.), views, opinions,
                  and interests.
                </li>
              </ul>

              <h1 className="font-medium text-lg pt-10">
                3.0 Important Disclaimers
              </h1>
              <p className="text-justify flex flex-col gap-3">
                <strong>
                  HONE CONNECT LTD HAS NO CONTROL OVER AND DOES NOT GUARANTEE
                  THE ACCURACY OF INFORMATION PROVIDED BY USERS. HONE CONNECT
                  LTD ASSUMES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR
                  ANOTHER USER OR THIRD PARTY POSTS, SENDS, RECEIVES, OR ACTS ON
                  THROUGH OUR SERVICES, NOR DOES HONE CONNECT LTD ASSUME ANY
                  RESPONSIBILITY FOR THE IDENTITY, INTENTIONS, LEGITIMACY, OR
                  VERACITY OF ANY USERS WITH WHOM YOU MAY COMMUNICATE WITH
                  THROUGH HONE CONNECT LTD.
                </strong>
              </p>
              <p className="text-justify">
                <strong>
                  YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR YOUR
                  SAFETY AND YOU UNDERSTAND THAT YOU SHOULD MEET WITH OTHER
                  INDIVIDUALS ONLY IN SAFE PUBLIC PLACES. YOU ARE SOLELY
                  RESPONSIBLE FOR CONDUCTING DUE DILIGENCE OF ANY INDIVIDUAL
                  REQUESTING TO MEET. HONE CONNECT LTD DISCLAIMS ANY
                  RESPONSIBILITY FOR USER’S INTERACTION WITH ANY INDIVIDUAL.
                  HONE CONNECT LTD MAKES NO GUARANTEES AS TO THE NUMBER OF
                  ACTIVE USERS AT ANY TIME; USERS' ABILITY OR DESIRE TO
                  COMMUNICATE WITH OR MEET YOU, OR THE ULTIMATE COMPATIBILITY
                  WITH OR CONDUCT BY USERS YOU MEET THROUGH THE SERVICES.
                </strong>
              </p>
              <p className="text-justify">
                <strong>
                  YOU HEREBY RELEASE HONE CONNECT LTD, OUR OFFICES, EMPLOYEES,
                  AGENTS, AND SUCCESSORS FROM CLAIMS, DEMANDS, ANY AND ALL
                  LOSSES, DAMAGES, RIGHTS, AND ACTIONS OF ANY KIND INCLUDING
                  PERSONAL INJURIES, DEATH, AND PROPERTY DAMAGE, THAT IS EITHER
                  DIRECTLY OR INDIRECTLY RELATED TO OR ARISES FROM ANY
                  INTERACTIONS WITH OR CONDUCT OF ANY INDIVIDUAL USING THE
                  SERVICES.
                </strong>
              </p>
              <p className="text-justify">
                <strong>
                  HONE CONNECT LTD DOES NOT REPRESENT OR WARRANT THAT OUR
                  SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR FREE. HONE
                  CONNECT LTD DOES NOT REPRESENT OR WARRANT THAT ANY DEFECTS OR
                  ERRORS IN OUR SERVICES WILL BE DISCOVERED OR CORRECTED. HONE
                  CONNECT LTD DOES NOT REPRESENT OR WARRANT THAT ANY CONTENT OR
                  INFORMATION YOU OBTAIN ON OR THROUGH OUR SERVICES WILL BE
                  ACCURATE, COMPLETE, CURRENT OR APPROPRIATE FOR YOUR PURPOSES.
                </strong>
              </p>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">
                  4.0 Account Eligibility
                </h1>
                <p className="mb-4">
                  By creating an account you represent and warrant that:
                </p>

                <ul className="flex flex-col gap-3 pl-5 list-disc">
                  <li >You are an individual at least 18 years old.</li>
                  <li>
                    You are legally qualified to enter a binding contract with
                    Hone Connect Ltd.
                  </li>
                  <li>
                    You are not prohibited by law from using our Services.
                  </li>
                  <li>
                    You do not have more than one account on our Services.
                  </li>
                  <li>
                    You have not previously been removed from our Services,
                    unless you have our express written permission to create a
                    new account.
                  </li>
                  <li>
                    You have not committed, been convicted of, or pled no
                    contest to a felony or crime.
                  </li>
                </ul>
              </div>

              <p>By creating an account you agree that:</p>

              <ul>
                <li>You comply with these terms of use agreement.</li>
                <li>
                  You comply to check this page from time to time to ensure you
                  are aware of any changes.
                </li>
                <li>
                  You comply with all applicable laws, including without
                  limitation, privacy laws, intellectual property laws,
                  anti-spam laws, and regulatory requirements.
                </li>
                <li>
                  You will take reasonable measures to protect the security of
                  your login information.
                </li>
                <li>
                  You will not misrepresent your identity, age, or affiliations
                  with a person or entity.
                </li>
                <li>
                  You will not use our Services for any harmful, illegal, or
                  nefarious purpose.
                </li>
                <li>
                  You will not use the Services in a way that damages the
                  Services or interfere with, disrupt, or negatively affect the
                  platform, the servers, or our Services' networks.
                </li>
                <li>
                  You will not harass, bully, stalk, intimidate, assault,
                  defame, harm, or otherwise abuse or cause psychological harm.
                </li>
                <li>You will not post or share Prohibited Content.</li>
                <li>
                  You will not solicit money or other items of value from
                  another user, whether as a gift, loan, or form of
                  compensation.
                </li>
                <li>You will not use our Services for hook-up.</li>
                <li>
                  You will not use our Services in relation to fraud or other
                  similar practice.
                </li>
                <li>
                  You will not disclose private or proprietary information that
                  you do not have the right to disclose.
                </li>
                <li>
                  You will not copy, modify, transmit, distribute, or create any
                  derivative works from our content, or any copyrighted
                  material, images, trademarks, trade names, service marks, or
                  other intellectual property, content, or proprietary
                  information accessible through our Services without Hone
                  Connect Ltd's prior written consent.
                </li>
                <li>
                  You will not upload viruses or other malicious code or
                  otherwise compromise the security of our Services.
                </li>
              </ul>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">5.0 Content</h1>

                <h3 className="font-medium text-sm pt-5">5.1 User Content</h3>
                <p className="mb-4">
                  You acknowledge and agree that Your Content may be viewed by
                  other users, and, notwithstanding these Terms, other users may
                  share Your Content with third parties. By uploading Your
                  Content, you represent and warrant to Hone Connect Ltd. that
                  you have all necessary rights and licenses to do so and
                  automatically grant Hone Connect Ltd. a license to use Your
                  Content. You understand and agree that Hone Connect Ltd. has
                  no obligation to display or review Your Content.
                </p>

                <div className="mb-4">
                  You understand and agree that we may monitor or review Your
                  Content, and we have the right to remove, delete, edit, limit,
                  or block or prevent access to any of Your Content at any time
                  at our sole discretion. You will not copy or use content
                  uploaded by other users other than the purpose as intended by
                  this terms of use. Content uploaded by other users may include
                  biased, incorrect, harmful, offensive, or misleading
                  information and you should always carefully review and
                  independently verify Content for accuracy.
                </div>

                <h3 className="font-medium text-sm pt-5">
                  5.2 Prohibited Content
                </h3>
                <div className="list-disc pl-5">
                  <ul className="list-disc">
                    <li>Obscene, pornographic, violent or contains nudity.</li>
                    <li>
                      Abusive, threatening, discriminatory or promotes racism,
                      sexism, hatred, or bigotry.
                    </li>
                    <li>
                      Encourages or facilitates any illegal activity without
                      limitation.
                    </li>
                    <li>Defamatory, libelous, or untrue.</li>
                    <li>
                      Relates to commercial activities (e.g., sales,
                      competitions, promotions, advertising, solicitation for
                      services, sex work, links to other websites or phone
                      numbers).
                    </li>
                    <li>Involves or facilitates the transmission of spam.</li>
                    <li>
                      Contains spyware, adware, viruses, corrupt files, worm
                      programs, or other malicious code.
                    </li>
                    <li>Infringes upon any third party's rights.</li>
                    <li>
                      Includes the image or likeness of another person without
                      that person's consent.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">
                  6.0 RIGHTS YOU GRANT
                </h1>
                <p className="mb-4">
                  By creating an account, you grant to Hone Connect Ltd. a
                  worldwide, perpetual, transferable, sub-licensable,
                  royalty-free right and license to host, store, use, copy,
                  display, reproduce, adapt, edit, publish, translate, modify,
                  reformat, incorporate into other works, advertise, distribute
                  and otherwise make available to the general public Your
                  Content, including any information you authorize us to access
                  from other third-party sources (if applicable), in whole or in
                  part, and in any way and in any format or medium currently
                  known or developed in the future.
                </p>
              </div>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">
                  7.0 PURCHASES AND SUBSCRIPTIONS
                </h1>
                <p className="mb-4">
                  You may be required to make purchases or purchase a
                  subscription for some products, services, or additional
                  features. If you purchase a subscription, it will
                  automatically renew until you cancel, in accordance with the
                  terms disclosed to you at the time of purchase.
                </p>
                <p className="mb-4">
                  You will continue to have access to your subscription benefits
                  until the end of your subscription period, at which point it
                  will expire. Purchases or Subscriptions may be made via credit
                  card or other payment processes on the website, in the app, or
                  via other external secure payment services.
                </p>
                <p className="mb-4">
                  If the Service Purchase includes an automatically renewing
                  subscription, you will continue to be periodically charged for
                  the subscription until you cancel. Because our Services may be
                  utilized without a subscription, canceling your subscription
                  does not remove your profile from our Services.
                </p>
                <p>
                  Generally, all purchases are final and nonrefundable, and
                  there are no refunds or credits for partially used periods.
                </p>
              </div>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">
                  8.0 ADVERTISEMENT AND THIRD-PARTY CONTENT
                </h1>
                <p className="mb-4">
                  Hone Connect Ltd's Services may contain advertisements and
                  promotions offered by third parties and links to other
                  websites or resources. Hone Connect is not responsible for the
                  availability (or lack of availability) of any external
                  websites or resources or their content.
                </p>
                <p className="mb-4">
                  Hone Connect is not responsible for, and does not endorse, any
                  products or services that may be offered by third-party
                  websites or resources. If you choose to interact with the
                  third parties made available through our Services, such
                  party's terms will govern their relationship with you.
                </p>
                <p>
                  Hone Connect is not responsible or liable for such third
                  parties' terms or actions.
                </p>
              </div>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">
                  9.0 ACCOUNT TERMINATION
                </h1>
                <p className="mb-4">
                  Hone Connect reserves the right to investigate and, if
                  appropriate, suspend or terminate your account without a
                  refund if Hone Connect believes that you have violated these
                  Terms, misused our Services, or behaved in a way that Hone
                  Connect regards as inappropriate or unlawful, on or off our
                  Services.
                </p>
                <p className="mb-4">
                  We reserve the right to make use of any personal,
                  technological, legal, or other means available to enforce the
                  Terms, at any time without liability and without the
                  obligation to give you prior notice, including, but not
                  limited to, preventing you from accessing the Services.
                </p>
                <p className="mb-4">
                  If your account is terminated by you or by Hone Connect for
                  any reason, these Terms continue and remain enforceable
                  between you and Hone Connect and you will not be entitled to
                  any refund for purchases made. Your information will be
                  managed in accordance with our Privacy Policy.
                </p>
                <p>
                  You can delete your account at any time by sending a mail to support@honeconnect.com
                </p>
              </div>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">10.0 INDEMNITY</h1>
                <p className="mb-4">
                  You agree to indemnify, defend, and hold harmless Hone Connect
                  Ltd., its successors, our subsidiaries, our affiliates, and
                  related companies and our respective officers, directors,
                  agents, employees, and representatives from and against any
                  and all complaints, demands, claims, damages, losses, costs,
                  liabilities, and expenses, including attorney's fees, due to,
                  arising out of, or relating in any way to:
                </p>
                <ul className="list-decimal list-inside space-y-2">
                  <li>Your use of Hone Connect Ltd. Services.</li>
                  <li>Your User Content.</li>
                  <li>Your conduct towards other users.</li>
                  <li>
                    Your violation of any representation, warranty, covenant, or
                    obligation stipulated in these Terms of Use agreement.
                  </li>
                  <li>Your violation of any applicable law.</li>
                </ul>
              </div>

              <div className="mb-10">
                <h1 className="font-medium text-lg pt-10">11.0 SAFETY</h1>
                <p className="mb-4">
                  You are solely responsible for your safety. You are solely
                  responsible for conducting due diligence of any individual
                  requesting to meet. You should always be cautious and adopt
                  safety measures, including but not limited to:
                </p>
                <ul className="list-decimal list-inside space-y-2">
                  <li>Protecting your personal information.</li>
                  <li>
                    Never sending money to any individual or sharing any
                    financial information.
                  </li>
                  <li>Meeting individuals in a safe public place.</li>
                </ul>
                <p className="mt-4">
                  You may wish to inform friends or family about any meeting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WebsiteFooter />
    </main>
  );
};

export default TermsOfUse;
