import { useQuery } from "@tanstack/react-query";
import apiClient from "../../ApiClient";

const getNotification = async () => {
  const res = await apiClient.get("chat/unread-count");
  return res.data;
};

export function useUnreadMessageCounter() {
  return useQuery({
    queryKey: ["unread_message_counter"],
    queryFn: getNotification,
  });
}
