import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import "./assets/css/index.css";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Create a client
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

reportWebVitals();
console.log("😆😆😆😆😆");

// document.addEventListener('contextmenu', function(event) {
//   event.preventDefault();
// });

document.addEventListener("copy", function (event) {
  event.preventDefault();
});

document.addEventListener("cut", function (event) {
  event.preventDefault();
});

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then((registration) => {
//         console.log(
//           "Service Worker registered with scope:",
//           registration.scope
//         );
//       })
//       .catch((error) => {
//         console.error("Service Worker registration failed:", error);
//       });
//   });
// }

// document.addEventListener('paste', function(event) {
//   event.preventDefault();
// });
