import { getConnections } from "../../api-services/api-query/connection-requests/useFetchConnections";
import AdsBanner from "../../app-layouts/AdsBanner";
import AppLayout from "../../app-layouts/AppLayout";
import HomePageSideNav from "../../app-components/HomeSideNav";
import { Link, useNavigate } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo } from "react";
import { APP_STORAGE, getUser } from "../../api-services/process";
import Preloader from "../../app-components/preloader/Preloader";
import { errorAlert, successAlert } from "../../app-util/SweetAlert";
import apiClient from "../../api-services/ApiClient";
import { useState } from "react";
import { LuUsers2 } from "react-icons/lu";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { Loading } from "../../assets/img";
import { TERipple } from "tw-elements-react";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import { useInterest } from "../../api-services/api-query/Interest/useInterests";
import { inputStyle } from "../../app-util/helpers";
import { UserProfileInterface } from "../../app-schemas/user.interface";

const HomePage = () => {
  const user: UserProfileInterface = getUser();
  const navigate = useNavigate();
  const { ref, inView } = useInView();
  const [laoding, setLoading] = useState(false);
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [showModal, setShowModal] = useState(false);
  const interestData = useInterest();

  const [countries, setCountries] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  // Filters state
  const [filters, setFilters] = useState<any>({
    country_id: null,
    state_id: null,
    city_id: null,
    date_range: null,
    interest_id: null,
    gender: null,
  });

  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    const countryId = filters?.country_id;

    if (user?.country_id.toString() !== countryId) {
      setFilters((prevState: any) => ({
        ...prevState,
        city_id: null,
        state_id: null,
      }));

      setStates([]);
      setCities([]);
    }
    if (countryId) handleStates(Number(countryId) ?? 0);
  }, [filters?.country_id, user?.country_id]);

  useEffect(() => {
    const stateId = filters?.state_id;
    if (stateId) handleCities(Number(stateId) ?? 0);
  }, [filters?.state_id]);

  const filterInterest = (data: any) => {
    if (data) {
      const uniqueInterests = new Map();

      data.forEach((item: any) => {
        const interest = item.interest;
        uniqueInterests.set(interest.id, interest);
      });
      const interestsArray = Array.from(uniqueInterests.values());

      return interestsArray;
    }

    return [];
  };

  const memoizedFilters = useMemo(() => filters, [filters]);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["all_connections", memoizedFilters],
      queryFn: async ({ pageParam = 1 }) =>
        getConnections({ ...memoizedFilters, page: pageParam }),
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.currentPage < lastPage.totalPages
          ? lastPage.currentPage + 1
          : undefined,
    });

  // handle-input-change
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any
    >
  ) => {
    console.log(e);
    const { name, value } = e.target;
    setFilters((prevState: any) => ({
      ...prevState,
      [name]: value ? value : null,
    }));
  };

  const handleCheckBox = (key: any, value: any) => {
    setFilters((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCheckBoxInterest = (name: string, value: number) => {
    if (value === null) {
      setFilters((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setFilters((prevFilters: any) => {
      // Check if the value is already in the array
      const interestIds = prevFilters[name] || [];
      const updatedIds = interestIds.includes(value)
        ? interestIds.filter((id: number) => id !== value) // Remove if already selected
        : [...interestIds, value]; // Add if not selected

      return {
        ...prevFilters,
        [name]: updatedIds,
      };
    });
  };

  const clearFilter = () => {
    setFilters({
      country_id: null,
      state_id: null,
      city_id: null,
      date_range: null,
      interest_id: null,
      gender: null,
    });
  };

  const getImageSrc = (post: any) => {
    if (post?.caption_image)
      return `${APP_STORAGE}gallery_pictures/${post.caption_image}`;
    else if (post?.user?.profile_picture)
      return `${APP_STORAGE}${post.user.profile_picture}`;
    return "img/PrivateAccount.png";
  };

  const getBadgeSrc = (badge: string) => {
    switch (badge) {
      case "GOLD":
        return "img/gold-medal.png";
      case "SILVER":
        return "img/gold-medal-2.png";
      default:
        return "";
    }
  };

  const handleConnect = async (uuid: any) => {
    if (!uuid) return;

    const data = {
      connection_request_id: uuid,
    };

    try {
      setLoading(true);
      const res = await apiClient.post("connection-requests-responses", data);
      successAlert();

      if (res.data.status) {
        navigate(SITELINKS.MY_RESPONSES);
      }
    } catch (error: any) {
      if (error.match("NO_PLAN")) {
        navigate(`${SITELINKS.SUBSCRIPTIONS}/${uuid}`);
      } else if (error.match("You have already responded")) {
        navigate(SITELINKS.MY_RESPONSES);
      } else {
        errorAlert(JSON.stringify(error), 20000);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCountries = async () => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get("countries");
      setCountries(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const handleStates = async (country: number) => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get(`countries/${country}/states`);
      setStates(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const handleCities = async (state: number) => {
    // setPageLoading(true);
    try {
      const res: any = await apiClient.get(`states/${state}/cities`);
      setCities(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      errorAlert("");
    } finally {
      // setPageLoading(false);
    }
  };

  const isVideo = (post: any): boolean => {
    // Define a list of common video file extensions
    const videoExtensions = [
      ".mp4",
      ".mov",
      ".avi",
      ".mkv",
      ".webm",
      ".flv",
      ".wmv",
    ];

    // Extract the file extension in lowercase
    const fileExtension = post?.caption_image
      ?.toLowerCase()
      .slice(post?.caption_image.lastIndexOf("."));

    // Check if the file extension is in the list of video extensions
    return videoExtensions.includes(fileExtension);
  };

  const connectionComponent = useMemo(
    () => {
      if (!data || data.pages.length === 0 || data.pages[0].data.length === 0) {
        return (
          <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 mt-[25vh]">
            <p className="mb-5 text-sm">No connection is available</p>
            <Link
              className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
              to={SITELINKS.MAKE_REQUEST}
            >
              Make a request
            </Link>
          </div>
        );
      }

      return data?.pages.flatMap((page) =>
        page.data.map((post: any, index: number) => (
          <div
            key={post.uuid}
            ref={index === 5 ? ref : undefined}
            className="w-full flex flex-col gap-1 bg-white dark:bg-slate-800 lg:rounded-10px sm:mb-0 p-3 py-3"
          >
            <div className="overflow-hidden max-h-[400px] min-h-[330px]  w-full rounded-xl border dark:border-slate-500">
              <TERipple rippleColor="#aaa">
                {isVideo(post) ? (
                  <video
                    muted
                    controls
                    tabIndex={0}
                    onFocus={(e) => {
                      console.log("Video focused");
                      e.currentTarget.play();
                    }}
                    onBlur={(e) => {
                      console.log("Video blurred");
                      e.currentTarget.pause();
                    }}
                    className="w-full h-[330px] md:h-[400px] rounded-xl bg-gray-100 dark:bg-slate-700 object-cover"
                  >
                    <source
                      src={`${APP_STORAGE}gallery_videos/${post.caption_image}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={getImageSrc(post)}
                    alt="Display"
                    className="w-full rounded-xl bg-gray-100 dark:bg-slate-700"
                  />
                )}
              </TERipple>
            </div>

            <div className="flex justify-between gap-2 items-center w-full">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <div className="text-xl font-semibold capitalize">
                    {post?.user?.username}
                  </div>

                  {post?.user?.badge && (
                    <img
                      src={getBadgeSrc(post.user.badge)}
                      alt={`${post.user.badge} Badge`}
                      className="w-8 h-8"
                    />
                  )}
                </div>

                <p className="font-normal text-sm">
                  {post?.location?.city}, {post?.location?.state},{" "}
                  {post?.location?.country}
                </p>
              </div>

              <div>
                {/*  border dark:border-slate-300 dark:bg-slate-800 */}
                <TERipple rippleColor="#aaa">
                  <button
                    className={`${
                      post?.status !== "accepted"
                        ? "bg-honeConnectPurple"
                        : "bg-[#6A6873]"
                    }  py-2 px-5 font-semibold text-sm text-white rounded-[5px]`}
                    onClick={() => {
                      handleConnect(post?.uuid ?? null);
                    }}
                  >
                    Connect
                  </button>
                </TERipple>
              </div>
            </div>

            <div className="font-medium text-xs text-honeConnectGray dark:text-gray-400 w-full">
              {post?.prespecified_text}
            </div>

            {/* connect interest */}
            <div className="flex gap-2 w-full">
              <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-normal text-honeConnectGray rounded-sm dark:text-gray-300 dark:bg-slate-800">
                {post?.interest}
              </p>
            </div>
          </div>
        ))
      );
    },

    // eslint-disable-next-line
    [data, ref]
  );

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  if (isLoading && !showModal) {
    return (
      <AppLayout title="Home">
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  return (
    <AppLayout title="Welcome">
      <div className="grid grid-cols-12 gap-8 justify-between">
        <HomePageSideNav />

        <div className="flex bg-hc_F4EBFA min-h-[100vh] dark:bg-slate-700 border-b border-gray-100 dark:border-slate-800 col-span-12 sm:col-span-7 w-full">
          <div className="flex flex-col w-full">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-28 mb-20 w-full">
              <div
                className={`shadow flex justify-between w-full bg-white dark:bg-slate-800 border-b dark:border-slate-600 z-10 fixed transition-all duration-500 top-0 ${
                  scrollDirection === null
                    ? "translate-y-[57px]"
                    : scrollDirection === "up"
                    ? "translate-y-0 top-[57px]"
                    : "-translate-y-full"
                }`}
              >
                <TERipple rippleColor="#aaa">
                  <button className="py-3 lg:relative lg:rounded-10px font-medium lg:text-lg lg:p-3 px-4 flex gap-3 items-center">
                    <LuUsers2
                      style={{ fill: "#d4b1e6" }}
                      className="fa-solid fa-users text-gray-600 dark:text-gray-300 text-2xl"
                    />
                    <p className="text-honeConnectBlack dark:text-gray-300">
                      {`${data?.pages[0]?.totalItems ?? "0"} Connect${
                        data?.pages[0]?.totalItems > 1 ? "s" : ""
                      }`}{" "}
                      / {data?.pages[0]?.totalUsers ?? "0"} Users
                    </p>
                  </button>
                </TERipple>

                <TERipple rippleColor="#aaa">
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="py-3 lg:text-lg lg:p-3 px-4 flex gap-2 items-center pr-3 font-medium"
                  >
                    <TbAdjustmentsHorizontal
                      style={{ fill: "#d4b1e6" }}
                      className="text-2xl text-gray-600 dark:text-gray-300"
                    />
                    <span className="text-honeConnectBlack hidden">
                      All filters
                    </span>
                  </button>
                </TERipple>
              </div>

              {connectionComponent}

              {isFetchingNextPage && (
                <div className="flex py-5 pb-16 justify-center">
                  <img
                    src={Loading}
                    alt="loader"
                    className="h-[20px] w-[20px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <AdsBanner />
      </div>

      {/* <!-- Modal --> */}
      <TEModal show={showModal}>
        <TEModalDialog className="rounded-0">
          <TEModalContent className="h-[100vh]  dark:bg-slate-800 overflow-scroll rounded-t-none">
            <TEModalHeader className="bg-honeConnectPurple dark:bg-slate-800 text-white rounded-t-none px-5">
              {/* <!--Modal title--> */}
              <h5 className="text-md font-normal leading-normal text-white">
                Filter Results
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>

            {/* <!--Modal body--> */}
            <TEModalBody className="h-[80vh] overflow-scroll">
              <div className="px-3 py-5">
                <p className="text-honeConnectBlack dark:text-gray-300 text-xs font-medium mb-3">
                  Filter by Interest
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5 items-center mt-2 mb-3">
                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name="interest_id"
                      id="col1"
                      className="h-5 w-5 accent-purple"
                      onChange={() => {
                        handleCheckBox("interest_id", null);
                      }}
                      checked={null === filters.interest_id}
                    />

                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      All
                    </span>
                  </label>

                  {filterInterest(interestData.data).map((item: any) => {
                    return (
                      <label
                        key={item.id}
                        className="items-center xl:col-span-1 custom-checkbox"
                      >
                        <input
                          type="checkbox"
                          name="interest_id"
                          id="col1"
                          className="h-5 w-5 accent-purple"
                          onChange={() => {
                            handleCheckBoxInterest("interest_id", item.id);
                          }}
                          checked={filters.interest_id?.includes(item.id)}
                        />

                        <span className="checkmark"></span>
                        <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                          {item.interest}
                        </span>
                      </label>
                    );
                  })}
                </div>
              </div>

              {/* filter by gender */}
              <div className="px-3 border-t py-5">
                <p className="text-honeConnectBlack dark:text-gray-300 text-xs font-medium mb-3">
                  Filter by Gender
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5 items-center mt-2 mb-3">
                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                      onChange={() => {
                        handleCheckBox("gender", null);
                      }}
                      checked={null === filters.gender}
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      All
                    </span>
                  </label>

                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                      onChange={() => {
                        handleCheckBox("gender", "OTHERS");
                      }}
                      checked={"OTHERS" === filters.gender}
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      Others
                    </span>
                  </label>

                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                      onChange={() => {
                        handleCheckBox("gender", "MALE");
                      }}
                      checked={"MALE" === filters.gender}
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      Male
                    </span>
                  </label>

                  <label className="items-center xl:col-span-1 custom-checkbox">
                    <input
                      type="checkbox"
                      name=""
                      id="col1"
                      className="h-5 w-5 accent-purple"
                      onChange={() => {
                        handleCheckBox("gender", "FEMALE");
                      }}
                      checked={"FEMALE" === filters.gender}
                    />
                    <span className="checkmark"></span>
                    <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                      Female
                    </span>
                  </label>
                </div>
              </div>

              {/* filter by date */}
              <div className="px-3 py-5 border-t">
                <p className="text-honeConnectBlack dark:text-gray-400 text-xs font-semibold mb-3">
                  Filter by Date
                </p>

                <div className="grid grid-cols-2 xl:grid-cols-2 gap-y-5">
                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                        onChange={() => {
                          handleCheckBox("date_range", null);
                        }}
                        checked={null === filters.date_range}
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        All time
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                        onChange={() => {
                          handleCheckBox("date_range", 7);
                        }}
                        checked={7 === filters.date_range}
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Last 7 days
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                        onChange={() => {
                          handleCheckBox("date_range", 3);
                        }}
                        checked={3 === filters.date_range}
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Last 3 days
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                        onChange={() => {
                          handleCheckBox("date_range", 1);
                        }}
                        checked={1 === filters.date_range}
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Last 24 hours
                      </span>
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <label className="items-center xl:col-span-1 custom-checkbox">
                      <input
                        type="checkbox"
                        name=""
                        id="col1"
                        className="h-5 w-5 accent-purple"
                        onChange={() => {
                          handleCheckBox("date_range", 0.5);
                        }}
                        checked={0.5 === filters.date_range}
                      />
                      <span className="checkmark"></span>
                      <span className="text-sm text-honeConnectGray dark:text-gray-400 font-medium">
                        Today
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {/* filter by location */}
              <div className="px-3 flex flex-col w-full mb-4">
                <label
                  htmlFor="country"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  Country
                </label>
                <select
                  value={filters.country_id}
                  name="country_id"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  <option value={""}>All Countries</option>
                  {countries?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="px-3 flex flex-col w-full mb-4">
                <label
                  htmlFor="state"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  State
                </label>
                <select
                  value={filters.state_id}
                  name="state_id"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="">Select Country First</option>
                  <option value={""}>All States</option>
                  {states?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="px-3 flex flex-col w-full mb-4">
                <label
                  htmlFor="city"
                  className="mb-2 text-sm font-medium dark:text-gray-400"
                >
                  City
                </label>
                <select
                  value={filters?.city_id}
                  name="city_id"
                  onChange={handleInputChange}
                  className={`${inputStyle} dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300`}
                >
                  <option value="">Select State First</option>
                  <option value={""}>All Cities</option>
                  {cities?.map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </TEModalBody>

            <TEModalFooter className="flex gap-3">
              <TERipple rippleColor="light" className="">
                <button
                  type="button"
                  className="w-full text-honeConnectPurple dark:text-gray-300 inline-block rounded bg-primary-100 px-6 py-3 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200 dark:bg-slate-800  dark:border-slate-400 border"
                  onClick={() => {
                    clearFilter();
                    setShowModal(false);
                  }}
                >
                  Reset
                </button>
              </TERipple>
              <TERipple rippleColor="light" className="w-2/3">
                <button
                  type="button"
                  className="w-full inline-block rounded px-6 py-3 text-sm font-medium uppercase text-gray-500 bg-honeConnectPurple text-white shadow  dark:text-gray-300 border dark:border-slate-600 dark:bg-slate-700"
                  onClick={() => setShowModal(false)}
                >
                  Show {`${data?.pages[0]?.totalItems ?? "0"}`}
                </button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      <Preloader loading={laoding} />
    </AppLayout>
  );
};

export default HomePage;
