import apiClient from "../../ApiClient";

export const getConnections = async (queryFilter: any, pageParam = 1) => {
  const res = await apiClient.get("connection-request", {
    params: queryFilter,
  });

  return {
    data: res.data.data ?? [],
    totalPages: res.data?.last_page ?? 0,
    totalItems: res.data?.total ?? 0,
    currentPage: res.data?.current_page ?? 0,
    totalUsers: res.data?.total_users ?? 0,
  };
};

// https://github.com/TanStack/query/issues/307
