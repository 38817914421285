import { LuCheck, LuCheckCheck } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useInbox } from "../../../api-services/api-query/messaging/useInbox";
import { APP_STORAGE, getUser } from "../../../api-services/process";
import Preloader from "../../../app-components/preloader/Preloader";
import AppLayout from "../../../app-layouts/AppLayout";
import { SITELINKS } from "../../../app-routes/links";
import { UserProfileInterface } from "../../../app-schemas/user.interface";
import { formatTimestampToRelativeTime, truncateText } from "../../../app-util";

const Inbox: React.FC<{
  selectUser: (userId: number, name: string, image: string) => void;
}> = ({ selectUser }) => {
  const user: UserProfileInterface = getUser();
  const inbox = useInbox();

  return (
    <AppLayout>
      <div className="bg-hc_F4EBFA dark:bg-slate-700 min-h-[82vh] mt-[60px]">
        <div className="p-2 space-y-2">
          {inbox.isLoading && <Preloader loading={true} />}

          {inbox.isFetched &&
            inbox.data?.map((item: any, index: number) => (
              <Link
                to={`${SITELINKS.CHATIN}`}
                key={index}
                className="flex items-center justify-between bg-white p-4 shadow rounded-lg cursor-pointer dark:bg-slate-800 hover:bg-blue-50"
                onClick={() => {
                  selectUser(
                    item.receiver?.id === user.id
                      ? item.sender?.id
                      : item.receiver?.id,

                    item.receiver?.id === user.id
                      ? item.sender?.username
                      : item.receiver?.username,

                    item.receiver?.id === user.id
                      ? APP_STORAGE + item.sender?.profile_pix_link
                      : APP_STORAGE + item.receiver?.profile_pix_link
                  );
                }}
              >
                <div className="flex gap-3">
                  <div className="h-[50px] w-[50px]">
                    <img
                      src={
                        item.receiver?.id === user.id
                          ? APP_STORAGE + item.sender?.profile_pix_link
                          : APP_STORAGE + item.receiver?.profile_pix_link
                      }
                      alt="mine"
                      className="h-[50px] w-[50px] rounded-lg"
                    />
                  </div>

                  <div>
                    <h3 className="font-semibold dark:text-gray-300">
                      {item.receiver?.id === user.id
                        ? item.sender?.username
                        : item.receiver?.username}
                    </h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {truncateText(item.message, 17)}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="text-xs text-gray-500 dark:text-gray-400 flex justify-end w-full">
                    <span>{!!item?.is_read ? <LuCheckCheck className="text-green-600" /> : <LuCheck />}</span>
                  </div>
                  <div className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                    {formatTimestampToRelativeTime(item.created_at)}
                  </div>
                </div>
              </Link>
            ))}

          {inbox.isFetched && inbox?.data?.length === 0 && (
            <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 mt-[25vh]">
              <p className="mb-5 text-sm">No chat history</p>
              <Link
                className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
                to={SITELINKS.MAKE_REQUEST}
              >
                Make a request
              </Link>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};

export default Inbox;
