import { HoneConnectLogoII } from "../../../assets/img";
import { FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const WebsiteFooter = () => (
  <>
    <footer className="bg-honeConnectPurple text-white py-8">
      <div className="w-11/12 mx-auto grid grid-cols-12 items-center justify-center">
        <div className="order-3 sm:order-2 xl:order-1 col-span-12 sm:col-span-7 xl:col-span-3 flex flex-col gap-2 text-center md:text-left">
          <img
            src={HoneConnectLogoII}
            alt="Footer Logo"
            className="w-28 mx-auto md:mx-0 mb-2 md:mb-0"
          />
          <p className="font-medium text-sm">
            Hone Connect Copyright @ 2024. <br />
            All rights reserved.
          </p>
        </div>

        <div className="order-2 sm:order-1 xl:order-2 col-span-full sm:col-span-12 xl:col-span-7 flex flex-col mb-10">
          <ul className="flex flex-wrap justify-center items-center gap-8 text-center sm:gap-16 text-sm font-medium">
            <li>
              <Link to="#!">Our Benefit</Link>
            </li>
            {/* <li>
              <Link to="/how-it-works">How It Works</Link>
            </li> */}
            <li>
              <Link to="#!">FAQ</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>

        <div className="order-1 sm:order-3 xl:order-3 col-span-12 sm:col-span-5 xl:col-span-2 flex flex-col gap-3 text-sm font-medium items-center mb-10 md:mb-0">
          <p className="text-lg">Follow US</p>
          <div className="flex gap-8 text-2xl">
            <a
              href="https://x.com/honeconnect001"
              rel="noreferrer"
              target="_blank"
            >
              <FaXTwitter />
            </a>
            <a
              href="https://instagram.com/hone_connect001"
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
            <a
              href="https://facebook.com/honeconnect"
              rel="noreferrer"
              target="_blank"
            >
              <FaFacebookF />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </>
);

export default WebsiteFooter;