import AdsBanner from "../../app-layouts/AdsBanner";
import AppLayout from "../../app-layouts/AppLayout";
import HomePageSideNav from "../../app-components/HomeSideNav";
import { Link } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useEffect, useMemo, useState } from "react";
import Preloader from "../../app-components/preloader/Preloader";
import { LuAlarmClockOff, LuCheckCircle, LuXCircle } from "react-icons/lu";
import { Loading } from "../../assets/img";
import { getUsersPayments } from "../../api-services/api-query/payments/useUserPayments";
import { formatTimestamp } from "../../app-util";
import { LucideBriefcaseBusiness } from "lucide-react";

const MyPayments = () => {
  const { ref, inView } = useInView();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ["my_payment"],
      queryFn: getUsersPayments,
      initialPageParam: 1,
      getNextPageParam: (data) =>
        data.currentPage < data.totalPages ? data.currentPage + 1 : undefined,
    });

  const paymentComponent = useMemo(() => {
    if (!data || data.pages.length === 0 || data.pages[0].data.length === 0) {
      return (
        <div className="text-center text-gray-500 dark:text-gray-200 dark:bg-slate-700 mt-[25vh]">
          <p className="mb-5 text-sm">No payment history available</p>
          <Link
            className="bg-honeConnectPurple dark:bg-slate-800 dark:text-gray-300 py-2 px-5 border text-white shadow rounded-lg"
            to={SITELINKS.MY_PAYMENTS}
          >
            Subscribe
          </Link>
        </div>
      );
    }

    return data.pages.flatMap((page) =>
      page.data.map((post: any, index: number) => (
        <div
          key={post.id}
          ref={index === 5 ? ref : undefined}
          className="flex gap-5 bg-white dark:bg-slate-800 lg:rounded-10px sm:mb-0 p-2 py-3 mx-2 rounded-10px p-3.5 items-center"
        >
          <div className="overflow-hidden w-[45px] ">
            {post?.status === "PAID" ? (
              <LuCheckCircle className="text-green-600 text-[40px] bg-gray-100 dark:border-slate-700 border rounded-full p-1" />
            ) : post?.status === "FAILED" ? (
              <LuXCircle className="text-red-600 text-[40px] bg-gray-100 dark:border-slate-700 border rounded-full p-1" />
            ) : (
              <LuAlarmClockOff className="text-blue-600 text-[40px] bg-gray-100 dark:border-slate-700 border rounded-full p-1" />
            )}
          </div>

          <div className="">
            {/* connect interest */}
            <div className="">
              <p className="text-sm flex gap-4 font-semibold dark:text-gray-300">
                Ref: {post?.payment_reference}
                <span className="bg-gray-100 dark:bg-slate-800 border dark:text-gray-300 text-xs flex items-center font-semibold rounded-full px-2">
                  ₦{post?.amount}
                </span>
              </p>
            </div>

            <div className="dark:text-gray-400">
              <p className="text-xs flex gap-2 font-medium">
                Plan: {post?.subscription_plan}
              </p>
              <p className="text-xs flex gap-2 font-medium">
                Status: {post?.status === "PENDING" ? "NOT PAID" : post?.status}
              </p>
              <p className="text-xs flex gap-2 font-normal">
                Expires: {formatTimestamp(post?.end_date, "date")}
              </p>
            </div>
          </div>
        </div>
      ))
    );
    // eslint-disable-next-line
  }, [data, ref]);

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  if (isLoading) {
    return (
      <AppLayout title="Home">
        <Preloader loading={true} />
      </AppLayout>
    );
  }

  return (
    <AppLayout title="My Request">
      <div className="grid grid-cols-12 gap-8 justify-between">
        <HomePageSideNav />

        <div
          className={`shadow flex justify-between w-full bg-white dark:bg-slate-800 border-b dark:border-slate-600 z-10 fixed transition-all duration-500 top-0 p-3 py-3 ${
            scrollDirection === null
              ? "translate-y-[57px]"
              : scrollDirection === "up"
              ? "translate-y-0 top-[57px]"
              : "-translate-y-full"
          }`}
        >
          <p className="text-lg font-medium text-honeConnectBlack dark:text-gray-300 flex gap-2 items-center">
            <LucideBriefcaseBusiness className="" /> Payments
          </p>
        </div>

        <div className="flex bg-hc_F4EBFA min-h-[100vh] dark:bg-slate-700 border-b border-gray-100 dark:border-slate-800 col-span-12 sm:col-span-7 w-full">
          <div className="flex flex-col w-full">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-28 pt-1 mb-28 w-full">
              {paymentComponent}

              {isFetchingNextPage && (
                <div className="flex py-5 pb-16 justify-center">
                  <img
                    src={Loading}
                    alt="loader"
                    className="h-[20px] w-[20px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <AdsBanner />
      </div>
    </AppLayout>
  );
};

export default MyPayments;
