import { LuAlignJustify, LuBell, LuSearch, LuX } from "react-icons/lu";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  RiAccountPinCircleLine,
  RiGift2Line,
  RiLogoutCircleLine,
  RiSecurePaymentLine,
  RiSettings5Line,
  RiShareCircleLine,
} from "react-icons/ri";
import { SITELINKS } from "../../app-routes/links";
import { HoneConnectLogo, LogoWhite, UserImg } from "../../assets/img";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { APP_STORAGE, getUser } from "../../api-services/process";
import { TERipple } from "tw-elements-react";
import { useUnreadNotificationCounter } from "../../api-services/api-query/notifications/useUnreadNotificationCounter";
import apiClient from "../../api-services/ApiClient";
import { useTheme } from "../../app-store/ThemeContext";
import Preloader from "../../app-components/preloader/Preloader";

// main-component-ui
const MobileNav = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const user: UserProfileInterface = getUser();
  const unread_notification = useUnreadNotificationCounter();

  // gloabl state localstorage
  // const user: UserInterface = getUser();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? "up" : "down";
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const getBadgeSrc = (badge: string) => {
    switch (badge) {
      case "GOLD":
        return "img/gold-medal.png";
      case "SILVER":
        return "img/gold-medal-2.png";
      default:
        return "";
    }
  };

  const logout = async () => {
    try {
      setLoading(true);
      await apiClient.post("logout");
      localStorage.clear();
      navigate(SITELINKS.LOGIN);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleShareFeed = useCallback(() => {
    if (navigator.share) {
      navigator
        .share({
          title: "Honeconnect",
          text: "find me on honeconnect",
          url: "https://honeconnect.com",
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.error("Error sharing:", error));
    } else {
      console.log("Web Share API is not supported in this browser.");
    }
  }, []);

  return (
    <>
      {/* main top area */}
      <div
        className={`shadow-sm flex justify-between w-full px-4 py-3 bg-white dark:bg-slate-800 border-b dark:border-gray-700 z-50 fixed top-0 transition-all duration-500 ${
          scrollDirection === null
            ? "translate-y-0"
            : scrollDirection === "up"
            ? "translate-y-0"
            : "-translate-y-full"
        }`}
      >
        <div className="flex gap-2 items-center">
          {isDarkMode ? (
            <img src={LogoWhite} alt="tiny top" className="h-[35px]" />
          ) : (
            <img src={HoneConnectLogo} alt="tiny top" className="h-[35px]" />
          )}
        </div>

        <button className="hidden gap-2 flex justify-end w-[120px] text-gray-600 text-sm flex items-center border rounded-3xl px-3">
          <span className="">Search...</span>
          <span>
            <LuSearch className="text-gray-700 text-lg" />
          </span>
        </button>

        <div className="text-[1.5rem] flex gap-5 items-center">
          <TERipple>
            <Link to={SITELINKS.NOTIFICATIONS} className="relative rounded-3xl">
              <LuBell
                className={
                  unread_notification?.data?.unread_count > 0
                    ? "text-honeConnectPurple dark:text-[#e1acff]"
                    : "text-gray-700 dark:text-gray-300"
                }
              />
              {unread_notification?.data?.unread_count > 0 && (
                <span className="notification-pointer"></span>
              )}
            </Link>
          </TERipple>

          <TERipple className="flex items-center">
            <button onClick={toggleSidebar} className="rounded-3xl">
              {isOpen ? (
                <LuX className="text-gray-700 mr-1  dark:text-gray-200" />
              ) : (
                <span className="">
                  <LuAlignJustify className="text-2xl text-gray-600  dark:text-gray-200" />
                </span>
              )}
            </button>
          </TERipple>
        </div>
      </div>

      {/* pull out Sidebar */}
      <div>
        <div
          className={`fixed top-0 left-0 right-0 h-full w-5/6 bg-white dark:bg-slate-700 shadow-lg z-50 text-white transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <nav className="p-4 py-2.5 bg-custom-600 shadow-sm dark:bg-slate-700">
            <ul>
              <li className="flex gap-3">
                <Link to={`${SITELINKS.MY_PROFILE}/${user.username}`}>
                  <span className="block w-[40px] h-[40px] bg-[#223f64] rounded-lg">
                    {user.profile_pix_link ? (
                      <img
                        src={APP_STORAGE + user.profile_pix_link}
                        alt="User"
                        className="w-[40px] h-[40px] flex rounded-lg border border-gray-300"
                      />
                    ) : (
                      <img
                        src={UserImg}
                        alt="User"
                        className="w-[40px] h-[40px] flex rounded-lg border border-gray-300"
                      />
                    )}
                  </span>
                </Link>

                <Link to={`${SITELINKS.MY_PROFILE}/${user.username}`}>
                  <div className="text-sm block flex-col items-center relative">
                    <p className="gap-1 flex text-gray-600 dark:text-gray-300 capitalize text-lg font-medium">
                      <span>{user?.username}</span>
                      <span>
                        {user?.badge && (
                          <img
                            src={getBadgeSrc(user?.badge)}
                            alt={`${user?.badge} Badge`}
                            className="w-8 h-8"
                          />
                        )}
                      </span>
                    </p>
                    <p className="block text-[10px] text-gray-600 dark:text-gray-400 absolute bottom-[-10px] ">
                      {user?.email}
                    </p>
                  </div>
                </Link>
              </li>
            </ul>
          </nav>

          <nav className="p-4 py-2 text-gray-700 dark:text-gray-300 font-medium text-sm overfloy-y-scroll">
            <ul>
              <li className="mb-2">
                <Link
                  to={`${SITELINKS.MY_PROFILE}/${user.username}`}
                  className="flex gap-4 p-3 hover:bg-gray-50 dark:hover:bg-slate-800"
                >
                  <RiAccountPinCircleLine className="text-2xl" />
                  <span className="mt-1">Profile</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to={SITELINKS.SUBSCRIPTIONS}
                  className="flex gap-4 p-3 hover:bg-gray-50 dark:hover:bg-slate-800"
                >
                  <RiGift2Line className="text-2xl" />
                  <span className="mt-1">Subscription</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to={SITELINKS.MY_PAYMENTS}
                  className="flex gap-4 p-3 hover:bg-gray-50 dark:hover:bg-slate-800"
                >
                  <RiSecurePaymentLine className="text-2xl" />
                  <span className="mt-1">Payments</span>
                </Link>
              </li>

              <li className="mb-2">
                <Link
                  to={SITELINKS.SETTINGS}
                  className="flex gap-4 p-3 hover:bg-gray-50 dark:hover:bg-slate-800"
                >
                  <RiSettings5Line className="text-2xl" />
                  <span className="mt-1">Settings</span>
                </Link>
              </li>

              <li className="mb-2">
                <button onClick={handleShareFeed}
                  className="flex gap-4 p-3 hover:bg-gray-50 dark:hover:bg-slate-800"
                >
                  <RiShareCircleLine className="text-2xl" />
                  <span className="mt-1">Invite a friend</span>
                </button>
              </li>

              <li className="mb-2">
                <button
                  type="button"
                  onClick={() => {
                    logout();
                  }}
                  className="flex gap-4 p-3 hover:bg-gray-50 dark:hover:bg-slate-800 w-full"
                >
                  <RiLogoutCircleLine className="text-2xl" />
                  <span className="mt-1">Logout</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <div
          onClick={toggleSidebar}
          className={`fixed top-0 left-0 right-0 h-full w-full bg-[#00000092] dark:bg-[#00000099] shadow-lg z-40 text-white transform transition-transform duration-300 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        ></div>
      </div>

      <Preloader loading={loading} />
    </>
  );
};

export default MobileNav;
