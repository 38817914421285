import React, { useEffect, useState } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import SuggestionsSideBar from "../../app-components/SuggestionsSideBar";
import { useTheme } from "../../app-store/ThemeContext";
import { LuStar } from "react-icons/lu";
import { FaAddressBook, FaAt } from "react-icons/fa6";
import ContactCard from "../../app-components/contact-card/ContactCard";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { getUser } from "../../api-services/process";
import apiClient from "../../api-services/ApiClient";
import Preloader from "../../app-components/preloader/Preloader";

const Settings = () => {
  const user: UserProfileInterface = getUser();
  const { isDarkMode, toggleTheme } = useTheme();
  const [openSection, setOpenSection] = useState<string | null>(
    "account_privacy"
  );
  const [inputContacts, setInputContacts] = useState<any>("");
  const [inputEmail, setInputEmail] = useState<any>("");
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getExclusionList();
  }, []);

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  const handlePickContacts = async () => {
    const max_contacts =
      user.badge === "GOLD" ? 300 : user.badge === "SILVER" ? 50 : 5;

    // Filter out any numbers that are already in selectedContacts
    const existingTels = selectedContacts.map((contact: any) => {
      return Array.isArray(contact.tel)
        ? contact.tel.filter((tel: string) => tel.trim() !== "")
        : [];
    });

    if (existingTels.length >= max_contacts) {
      alert("Maximum phone exclusion reached");
      return;
    }

    if ("contacts" in navigator && navigator.contacts?.select) {
      try {
        const available_slots: number =
          Number(max_contacts) - Number(existingTels.length);

        const props = ["name", "tel"]; // Fields to fetch
        const opts = { multiple: true }; // Allow selecting multiple contacts

        const contacts = await navigator.contacts.select(props, opts);

        if (contacts.length > 0) {
          const firstDiffContacts = contacts.slice(0, available_slots);
          const updatedContacts = [...selectedContacts, ...firstDiffContacts];
          setSelectedContacts(updatedContacts);
          handleExclusionList(updatedContacts);

          alert(
            firstDiffContacts.length +
              " phone number added into your " +
              available_slots +
              " available slots"
          );
        }
      } catch (error) {
        console.error("Error accessing contacts:", error);
      }
    } else {
      alert("The Contacts Picker API is not supported in your browser.");
    }
  };

  const addEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!inputEmail && !emailRegex.test(inputEmail)) {
      alert("Enter valid email");
      return;
    }

    const max_contacts =
      user.badge === "GOLD" ? 300 : user.badge === "SILVER" ? 50 : 5;
    const props = {
      name: "".split(","),
      email: inputEmail.split(","),
      tel: "".split(","),
    };

    // Filter out any numbers that are already in selectedContacts
    const existingEmails = selectedContacts.flatMap((contact: any) => {
      return Array.isArray(contact.email)
        ? contact.email.filter((email: string) => email.trim() !== "")
        : [];
    });

    const uniqueEmails = props.email.filter(
      (email: any) => !existingEmails.includes(email)
    );

    if (max_contacts === existingEmails.length) {
      alert("Maximum email address exclusion reached");
      return;
    }

    if (uniqueEmails.length > 0) {
      const newContact = { ...props, email: uniqueEmails };
      const updatedContacts = [...selectedContacts, newContact];
      setSelectedContacts(updatedContacts);
      handleExclusionList(updatedContacts);
    } else {
      alert("Email address already exist.");
    }
  };

  const addContact = () => {
    if (!inputContacts) {
      alert("Enter phone or select from phonebook");
      return;
    }
    const max_contacts =
      user.badge === "GOLD" ? 300 : user.badge === "SILVER" ? 50 : 5;
    const props = {
      name: "".split(","),
      email: "".split(","),
      tel: inputContacts.split(","),
    };

    // Filter out any numbers that are already in selectedContacts
    const existingTels = selectedContacts.flatMap((contact: any) => {
      return Array.isArray(contact.tel)
        ? contact.tel.filter((tel: string) => tel.trim() !== "")
        : [];
    });

    const uniqueTels = props.tel.filter(
      (tel: any) => !existingTels.includes(tel)
    );

    if (max_contacts === existingTels.length) {
      alert("Maximum phone exclusion reached");
      return;
    }

    if (uniqueTels.length > 0) {
      const newContact = { ...props, tel: uniqueTels };
      const updatedContacts = [...selectedContacts, newContact];
      setSelectedContacts(updatedContacts);
      handleExclusionList(updatedContacts);
    } else {
      alert("Phone numbers already exist in selected contacts.");
    }
  };

  const handleExclusionList = async (data: any) => {
    try {
      const formdata = {
        contacts: data,
      };
      setLoading(true);
      await apiClient.post("exclusion-contacts", formdata);
      getExclusionList();
    } catch (error: any) {
      console.log(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  const getExclusionList = async () => {
    try {
      const res = await apiClient.get(`exclusion-contacts`);
      const data = res.data;
      const excluded_contacts_formatted = data.map((contact: any) => ({
        ...contact,
        name: contact.name ? [contact.name] : [],
        email: contact.email ? [contact.email] : [],
        tel: contact.tel ? [contact.tel] : [],
      }));
      setSelectedContacts(excluded_contacts_formatted);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setLoading(true);
      await apiClient.delete(`exclusion-contacts/${id}`);
      getExclusionList();
    } catch (err) {
      console.log(err);
      alert("Error occured");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <main className="sm:py-10">
        <div className="grid grid-cols-12 gap-8 dark:bg-slate-800 justify-between w-full sm:w-11/12 mx-auto">
          <SuggestionsSideBar />

          <div className="h-[80vh] col-span-12 mx-auto w-full mt-8 mt-[60px]">
            {/* Account Privacy */}
            <div className="border-gray-300 border-b ">
              <button
                onClick={() => toggleSection("account_privacy")}
                className="w-full text-left px-4 py-4 font-medium flex justify-between items-center bg-white dark:bg-slate-800 text-sm border-b "
              >
                <span>
                  Themes
                  <br />
                  <small className="text-gray-600 dark:text-gray-400">
                    Set your app theme to dark or light mode
                  </small>
                </span>
                <span>{openSection === "account_privacy" ? "-" : "+"}</span>
              </button>

              {openSection === "account_privacy" && (
                <div className="px-4 py-2 bg-gray-50 dark:bg-slate-700 dark:text-gray-400 text-gray-700 pb-5">
                  <p className="text-sm">Choose Theme</p>
                  <div className="flex justify-between mt-5 font-medium">
                    <span>Light</span>

                    <div>
                      <input
                        className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300  
                        dark:border-gray-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full 
                        before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute 
                        after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary 
                        dark:checked:border-gray-300 checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 
                        checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary 
                        dark:checked:after:border-gray-300 checked:after:bg-primary 
                        dark:checked:after:bg-gray-300 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer 
                        hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 
                        focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                        focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary 
                        dark:checked:focus:border-gray-300 checked:focus:before:scale-100 
                        checked:focus:before:shadow-[0px_0px_0px_13px_#7600bc] 
                        dark:checked:focus:before:shadow-[0px_0px_0px_13px_#c8c8c8] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                        dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                        dark:checked:focus:before:shadow-[0px_0px_0px_13px_#c8c8c8]
                        "
                        type="radio"
                        id="flexRadioDefault"
                        checked={!isDarkMode}
                        onChange={toggleTheme}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between mt-5 font-medium">
                    <span>Dark</span>

                    <div>
                      <input
                        className="relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300  
                        dark:border-gray-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full 
                        before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute 
                        after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary 
                        dark:checked:border-gray-300 checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 
                        checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary 
                        dark:checked:after:border-gray-300 checked:after:bg-primary 
                        dark:checked:after:bg-gray-300 checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer 
                        hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 
                        focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] 
                        focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary 
                        dark:checked:focus:border-gray-300 checked:focus:before:scale-100 
                        checked:focus:before:shadow-[0px_0px_0px_13px_#7600bc] 
                        dark:checked:focus:before:shadow-[0px_0px_0px_13px_#c8c8c8] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                        dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] 
                        dark:checked:focus:before:shadow-[0px_0px_0px_13px_#c8c8c8]
                        relative float-left -ml-[1.5rem] mr-1 mt-0.5 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300  
                        dark:border-neutralDark before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full 
                        before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute 
                        after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary 
                        dark:checked:border-neutral-500 checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 
                        checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary 
                        dark:checked:after:border-neutral-500 checked:after:bg-primary 
                        dark:checked:after:bg-neutral-500 checked:after:content-[''] 
                        checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] 
                        hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 
                        focus:before:scale-100 focus:before:opacity-[0.12] 
                        focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                        checked:focus:border-primary dark:checked:focus:border-neutral-500 checked:focus:before:scale-100 
                        checked:focus:before:shadow-[0px_0px_0px_13px_#7600bc] 
                        dark:checked:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.3)] 
                        checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] 
                        dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.2)] 
                        dark:checked:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.2)]"
                        type="radio"
                        id="flexRadioDefault"
                        checked={isDarkMode}
                        onChange={toggleTheme}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Notification Settings */}
            <div className="border-gray-300 border-b hidden">
              <button
                onClick={() => toggleSection("notification_settings")}
                className="w-full text-left px-4 py-4 text-sm  font-medium flex justify-between items-center"
              >
                <span>
                  Notification Settings
                  <br />
                  <small className="text-gray-600 dark:text-gray-400">
                    Adjust your notification preferences
                  </small>
                </span>
                <span>
                  {openSection === "notification_settings" ? "-" : "+"}
                </span>
              </button>

              {openSection === "notification_settings" && (
                <div className="px-4 py-2 bg-gray-50 dark:bg-slate-700 dark:text-gray-400 text-gray-700 pb-5">
                  <p>
                    Adjust your notification preferences for emails and alerts.
                  </p>
                </div>
              )}
            </div>

            {/* Exclusion List */}
            <div>
              <button
                onClick={() => toggleSection("exclusion_list")}
                className="w-full text-left px-4 py-4 text-sm  font-medium flex justify-between items-center"
              >
                <span>
                  Exclusions (Privacy)
                  <br />
                  <small className="text-gray-600 dark:text-gray-400">
                    Adjust your privacy preferences
                  </small>
                </span>
                <span>{openSection === "exclusion_list" ? "-" : "+"}</span>
              </button>

              {openSection === "exclusion_list" && (
                <div className="px-4 py-2 bg-gray-50 dark:bg-slate-700 dark:text-gray-400 text-gray-700 pb-5 pb-[100px]">
                  {/* info abt exclusion benefits */}
                  <div>
                    <p className="flex gap-3 text-xs text-honeConnectGray dark:text-gray-400 font-medium mt-2 mb-1 items-center">
                      <span>
                        <LuStar className="text-xl" />
                      </span>
                      <span>
                        Your requests are hidden from contacts you've excluded.
                      </span>
                    </p>

                    {/* <p className="flex gap-3 text-xs text-honeConnectGray dark:text-gray-400 font-medium mb-1 items-center">
                      <span>
                        <LuStar className="text-xl" />
                      </span>
                      <span>
                        You will be able to import contacts (emails/phone
                        numbers) your mobile device to the exclusion list.
                      </span>
                    </p> */}

                    <p className="flex gap-3 text-xs text-honeConnectGray dark:text-gray-400 font-medium mb-1 items-center">
                      <span>
                        <LuStar className="text-xl" />
                      </span>
                      <span>
                        <span className="text-honeConnectOrange">
                          Standard users:
                        </span>{" "}
                        5 phone numbers and 5 email addresses.
                      </span>
                    </p>

                    <p className="flex gap-3 text-xs text-honeConnectGray dark:text-gray-400 font-medium mb-1 items-center">
                      <span>
                        <LuStar className="text-xl" />
                      </span>
                      <span>
                        <span className="text-honeConnectOrange">
                          Silver users:
                        </span>{" "}
                        50 phone numbers and 50 email addresses
                      </span>
                    </p>
                    <p className="flex gap-3 text-xs text-honeConnectGray dark:text-gray-400 font-medium mb-1 items-center">
                      <span>
                        <LuStar className="text-xl" />
                      </span>
                      <span>
                        <span className="text-honeConnectOrange">
                          Gold users:
                        </span>{" "}
                        300 phone numbers and 300 email addresses
                      </span>
                    </p>
                  </div>

                  {/* exclusion */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-5 mb-[100px]">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm text-honeConnectGray dark:text-gray-300 font-medium">
                        Enter Phone Number
                      </p>

                      <div className="flex gap-2 mb-1">
                        <div className="relative w-3/4">
                          <input
                            className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a] pr-10"
                            placeholder="080X XXXX XXX"
                            value={inputContacts}
                            type="tel"
                            onChange={(e) => setInputContacts(e.target.value)}
                          />

                          <button
                            type="button"
                            className="absolute right-1 top-1 text-honeConnectPurple dark:text-gray-300 border p-3 rounded-r-lg border-0 text-base"
                            onClick={handlePickContacts}
                          >
                            <FaAddressBook />
                          </button>
                        </div>
                        <button
                          className="w-1/4 xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                          onClick={addContact}
                        >
                          Add
                        </button>
                      </div>

                      <p className="text-sm text-honeConnectGray dark:text-gray-300 font-medium">
                        Enter Email
                      </p>

                      <div className="flex gap-2 mb-1">
                        <div className="relative w-3/4">
                          <input
                            className="w-full xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a] pr-10"
                            placeholder="youremail@mail.com"
                            value={inputEmail}
                            type="email"
                            onChange={(e) => setInputEmail(e.target.value)}
                          />

                          <button
                            type="button"
                            className="absolute right-1 top-1 text-honeConnectPurple dark:text-gray-300 border p-3 rounded-r-lg border-0 text-base"
                          >
                            <FaAt />
                          </button>
                        </div>
                        <button
                          className="w-1/4 xl:w-1/5 bg-honeConnectGray50 dark:bg-slate-800 border border-solid border-honeConnectGray200 dark:border-slate-300 text-gray-600  dark:text-gray-300 p-3.5 py-2 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal hover:shadow-[#7600bc0a]"
                          onClick={addEmail}
                        >
                          Add
                        </button>
                      </div>

                      {!!selectedContacts.length && (
                        <>
                          {selectedContacts.map(
                            (contact: any, index: number) => (
                              <ContactCard
                                key={index}
                                contact={contact}
                                onDelete={handleDelete}
                              />
                            )
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <AdsBanner></AdsBanner>
        </div>
      </main>
      <Preloader loading={loading} />
    </AppLayout>
  );
};

export default Settings;
