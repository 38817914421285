import React, { useState, useEffect, FormEvent } from "react";
import AppLayout from "../../app-layouts/AppLayout";
import AdsBanner from "../../app-layouts/AdsBanner";
import MyProfileSideBar from "../home/myProfileSideBar";
import { UserProfileInterface } from "../../app-schemas/user.interface";
import { APP_STORAGE, getUser, storeUser } from "../../api-services/process";
import { Link, useParams } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { FaChevronCircleLeft } from "react-icons/fa";
import {
  Group1000004983,
  IG,
  Snapchat,
  UserImg,
  X,
  GOLD,
  SILVER,
  PhoneCall,
  WhatsApp,
} from "../../assets/img";
import { calculateAge } from "../../app-util/CalculateAge";
import { LuMapPin } from "react-icons/lu";
import { TERipple } from "tw-elements-react";
import apiClient from "../../api-services/ApiClient";
import Preloader from "../../app-components/preloader/Preloader";
import { FaComments } from "react-icons/fa6";
import { useTheme } from "../../app-store/ThemeContext";
import { ProfileInterface } from "../../app-schemas/profile.interface";
import {
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import PhoneInput from "react-phone-input-2";
import OTPInput from "react-otp-input";
import { successAlert } from "../../app-util/SweetAlert";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const Profile = () => {
  const { isDarkMode } = useTheme();
  const myinfo: UserProfileInterface = getUser();
  const { id } = useParams();
  const [copySuccess, setCopySuccess] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<Partial<ProfileInterface>>({});
  const [userChat, setUserChat] = useState<any>({});
  const [gallery, setGallery] = useState<any>([]);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    myinfo?.phone_number
  );
  const [error, setError] = useState("");
  const [resendCode, setResendCode] = useState<boolean>(true);
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const getBadgeSrc = (badge: string) => {
    switch (badge) {
      case "GOLD":
        return GOLD;
      case "SILVER":
        return SILVER;
      default:
        return "";
    }
  };
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess(null);
    }
  };

  useEffect(() => {
    if (id) {
      getUserInfo(id);
      getAuthenticatedUserGallery(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (timeLeft === 0) {
      setResendCode(false);
    }
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const getUserInfo = async (id: string) => {
    try {
      setLoading(true);
      const res = await apiClient.get(`user-info/${id}`);
      const user = res.data;
      setUser(user);

      const chatData = {
        id: user.id,
        name: user.username,
        image: APP_STORAGE + user?.profile_picture,
      };

      setUserChat(JSON.stringify(chatData));

      if (user.id !== myinfo.id && !myinfo.is_phone_verified) {
        setShowModal(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getAuthenticatedUserGallery = async (id: string) => {
    try {
      const res = await apiClient.get(`user-gallery/${id}`);
      setGallery(res.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const requestPhone = async (id: string) => {
    try {
      setLoading(true);
      await apiClient.patch(`request-phone-permission/${id}`);
      await getUserInfo(id);
      alert("phone request sent");
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const acceptPhone = async (id: string) => {
    try {
      setLoading(true);
      await apiClient.patch(`accept-phone-permission/${id}`);
      await getUserInfo(id);
      alert("phone request approved");
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  // Format time into MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  };

  // handle-verifiy phone
  const handleVerification = async (e: FormEvent) => {
    e.preventDefault();
    const result = otp.length === 4;

    setError("");
    if (!result) {
      setError("code must be 6 digit in length");
      return;
    }

    try {
      setLoading(true);
      const data = {
        phone: phoneNumber,
        code: otp,
      };

      const res = await apiClient.post("verify-phone-code", data);

      if (res.data.status) {
        successAlert();
        setShowModal(false);
        const myinfoUpdated = { ...myinfo };
        myinfoUpdated.is_phone_verified = true;

        storeUser(myinfoUpdated);
      }
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  // handle-phone-verification code
  const handleResend2FA = async (e: FormEvent) => {
    e.preventDefault();

    setError("");
    if (!phoneNumber) {
      setError("Cannot complete process, kindly return to sign in");
      return;
    }

    try {
      setLoading(true);
      setResendCode(true);

      let res = await apiClient.post("request-phone-verification", {
        phoneNumber,
      });

      if (!!res.data.sms_response?.error) {
        setResendCode(false);
        setError(JSON.stringify(res.data.sms_response.error));
      } else {
        setTimeLeft(180);
      }
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  if (!id) {
    return (
      <AppLayout>
        <p className="mt-30">Cannot load profile, try again later</p>
      </AppLayout>
    );
  }

  if (loading) {
    return (
      <AppLayout>
        <div className="h-[100vh]">
          <Preloader loading={true} />
        </div>
      </AppLayout>
    );
  }

  if (!loading && !user) {
    return (
      <AppLayout>
        <p className="mt-30">Cannot load profile, try again later</p>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      {!showModal && (
        <main className="sm:py-10">
          <div className="grid grid-cols-12 gap-8 justify-between w-full sm:w-11/12 mx-auto">
            <MyProfileSideBar></MyProfileSideBar>

            <div className="col-span-12 md:col-span-8 lg:col-span-7 sm:rounded-10px">
              <div
                style={{
                  backgroundImage: isDarkMode ? "" : `url(${Group1000004983})`,
                }}
                className="bg-cover bg-honeConnectPurple dark:bg-slate-700 bg-center p-6 py-20 sm:rounded-t-[10px] h-[110px] sm:-mb-0"
              >
                <p className="sm:hidden text-sm mb-8 font-semibold hidden">
                  <Link to={SITELINKS.HOME} className="text-white">
                    <FaChevronCircleLeft /> Back
                  </Link>
                </p>

                <div className="flex gap-4 sm:gap-6 items-center">
                  <div className="relative w-2/6">
                    <div className="max-h-[100px] overflow-hidden border-honeConnectPurple dark:border-slate-700 border border-2 rounded-lg">
                      {user?.profile_picture ? (
                        <PhotoProvider>
                          <div className="flex gap-6 flex-wrap items-center">
                            <PhotoView
                              src={`${APP_STORAGE}${user?.profile_picture}`}
                            >
                              <img
                                src={`${APP_STORAGE}${user?.profile_picture}`}
                                alt="User"
                                className="w-40"
                              />
                            </PhotoView>
                          </div>
                        </PhotoProvider>
                      ) : (
                        <img src={UserImg} alt="User" className="w-40" />
                      )}
                    </div>

                    {user?.badge && (
                      <img
                        src={getBadgeSrc(user?.badge)}
                        alt={`${user?.badge} Badge`}
                        className="w-16 absolute -top-6 -right-6"
                      />
                    )}
                  </div>

                  <div className="text-white dark:text-gray-300  flex-col gap-2 mt-1 sm:mt-10">
                    <p className="font-medium text-lg capitalize">
                      {user?.username},{" "}
                      {calculateAge(user?.date_of_birth ?? "")}
                    </p>

                    <div className="flex gap-2 text-xs font-normal items-center">
                      <LuMapPin />
                      <p>
                        {user?.location?.area} - {user?.location?.city},{" "}
                        {user?.location?.state} {user?.location?.country}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {myinfo.id === user.id && (
                <Link
                  to={SITELINKS.EDIT_PROFILE}
                  className="dark:text-honeConnectOrange flex-col text-honeConnectPurple font-medium text-sm underline self-start mt-2 px-6 pt-2 w-full block bg-white dark:bg-slate-800 text-end"
                >
                  Edit Profile
                </Link>
              )}

              {/* my bio */}
              <div className="bg-white dark:bg-slate-800  p-6 sm:pt-[100px] sm:pt-8">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-base text-honeConnectBlack dark:text-gray-300 ">
                    My Bio
                  </p>
                  <div className="flex flex-col justify-between">
                    <p className="text-honeConnectBlack dark:text-gray-400 font-normal text-sm">
                      {user?.bio ?? "Hello, i am available"}
                    </p>
                  </div>
                </div>
              </div>

              {/* connect */}
              {myinfo.id !== user.id && (
                <div className="bg-white dark:bg-slate-800 p-6 mt-2">
                  <div className="flex flex-col">
                    <p className="font-semibold text-base text-honeConnectBlack dark:text-gray-300">
                      Phone Call & Whatsapp
                    </p>

                    {!user?.phone_number &&
                      user?.contact_request_receiver_id !== myinfo.id && (
                        <div className="text-xs dark:text-gray-400">
                          Connect via Phone/Whatsapp?{" "}
                          {!user?.is_request_contact ? (
                            <button
                              className="dark:text-gray-300 border-b text-honeConnectPurple border-honeConnectPurple font-medium"
                              type="button"
                              onClick={() => {
                                requestPhone(id);
                              }}
                            >
                              Make a Request
                            </button>
                          ) : (
                            <button
                              className="dark:text-purple-300 border-b dark:border-purple-300 text-honeConnectPurple border-honeConnectPurple font-medium"
                              type="button"
                            >
                              Request Sent!
                            </button>
                          )}
                        </div>
                      )}

                    {!user?.phone_number &&
                      user?.contact_request_receiver_id === myinfo.id && (
                        <div className="text-xs dark:text-gray-400 flex gap-2">
                          <span>
                            <span className="capitalize">{user.username}</span>{" "}
                            request to connect with your through your Phone &
                            Whatsapp?{" "}
                            <button
                              className="dark:text-purple-400 text-honeConnectPurple border-honeConnectPurple dark:border-purple-400 border-b font-medium"
                              type="button"
                              onClick={() => {
                                acceptPhone(id);
                              }}
                            >
                              Accept Request
                            </button>
                          </span>
                        </div>
                      )}

                    <div className="flex justify-between gap-12 pt-4">
                      {user?.phone_number ? (
                        <a
                          href={`tel:${user?.phone_number}`}
                          target="_blank"
                          rel="noreferrer"
                          className="w-1/2"
                        >
                          <div className="flex flex-col gap-3 items-center">
                            <img
                              src={PhoneCall}
                              alt="PhoneCall"
                              className="w-[60px]"
                            />
                            <p className="text-xs font-medium text-honeConnectGray  dark:text-gray-400 text-center">
                              Call on <br />
                              Phone
                            </p>
                          </div>
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            alert("Kindly request to connect via phone call");
                          }}
                          className="w-1/2"
                        >
                          <div className="flex flex-col gap-3 items-center">
                            <img
                              src={PhoneCall}
                              alt="PhoneCall"
                              className="w-[60px]"
                            />
                            <p className="text-xs font-medium text-honeConnectGray  dark:text-gray-400 text-center">
                              Call on <br />
                              Phone
                            </p>
                          </div>
                        </button>
                      )}

                      {user?.phone_number ? (
                        <a
                          href={`https://wa.me/${user?.phone_number}`}
                          target="_blank"
                          rel="noreferrer"
                          className="w-1/2"
                        >
                          <div className="flex flex-col gap-3 items-center">
                            <img
                              src={WhatsApp}
                              alt="WhatsApp"
                              className="w-[60px]"
                            />
                            <p className="text-xs font-medium text-honeConnectGray dark:text-gray-400 text-center">
                              Chat on WhatsApp
                            </p>
                          </div>
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            alert("Kindly request to connect via phone call");
                          }}
                          className="w-1/2"
                        >
                          <div className="flex flex-col gap-3 items-center">
                            <img
                              src={WhatsApp}
                              alt="WhatsApp"
                              className="w-[60px]"
                            />
                            <p className="text-xs font-medium text-honeConnectGray dark:text-gray-400 text-center">
                              Chat on WhatsApp
                            </p>
                          </div>
                        </button>
                      )}

                      <Link
                        to={SITELINKS.CHATIN}
                        onClick={() => {
                          localStorage.setItem("lastChattedUserInfo", userChat);
                        }}
                        className="w-1/2"
                      >
                        <div className="flex flex-col gap-3 items-center">
                          <div className="text-white bg-[#7600bc]  dark:border-slate-700 shadow p-2.5 rounded-[50%] border">
                            <FaComments className="text-[40px]" />
                          </div>
                          <p className="text-xs dark:text-gray-400 font-medium text-honeConnectGray text-center">
                            Chat on HoneConnect
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {/* my interest */}
              <div className="bg-white dark:bg-slate-800 p-6 mt-2">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-base text-honeConnectBlack dark:text-gray-300">
                    My Interest
                  </p>

                  <div className="flex-col justify-between lg:gap-28">
                    <div className="flex flex-wrap mb-2 mt-2">
                      <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200  dark:border-gray-300 bg-honeConnectGray50  dark:bg-slate-800 font-medium text-honeConnectGray  dark:text-gray-300 rounded-[5px]">
                        {!!user && user.interests && user.interests.length > 0
                          ? user.interests[0]
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* social */}
              <div className="bg-white  dark:bg-slate-800 p-6 mt-2">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-base text-honeConnectBlack dark:text-gray-300">
                    Social Account
                  </p>

                  <div className="flex flex-wrap justify-between gap-28">
                    <div className="grid grid-cols-2 sm:grid-cols-3 gap-10 items-center">
                      <div className="flex gap-2 items-center">
                        <img src={IG} alt="" className="w-10" />
                        <div className="flex flex-col gap-1">
                          <p className="text-xs text-honeConnectGray  dark:text-gray-300 font-medium">
                            Instagram
                          </p>
                          <p className="text-sm font-normal text-honeConnectBlack  dark:text-gray-400">
                            {user?.instagram_id ?? "Not found"}
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-2 items-center">
                        <img src={Snapchat} alt="" className="w-10" />
                        <div className="flex flex-col gap-1">
                          <p className="text-xs text-honeConnectGray dark:text-gray-300 font-medium">
                            Snapchat
                          </p>
                          <p className="text-sm font-normal text-honeConnectBlack dark:text-gray-400">
                            {user?.snapchat_id ?? "Not found"}
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-2 items-center">
                        <img src={X} alt="" className="w-10" />
                        <div className="flex flex-col gap-1">
                          <p className="text-xs text-honeConnectGray font-medium dark:text-gray-300">
                            X
                          </p>
                          <p className="text-sm font-normal text-honeConnectBlack dark:text-gray-400">
                            {user?.twitter_x_id ?? "Not found"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="hidden xl:block"></div>
                  </div>
                </div>
              </div>

              {/* my photos */}
              <div className="bg-white dark:bg-slate-800 p-6 mt-2">
                <div className="flex flex-col gap-2">
                  <p className="font-semibold text-base text-honeConnectBlack dark:text-gray-300">
                    Photos
                  </p>

                  <div className="flex justify-between gap-28">
                    <div className="flex gap-10 flex-wrap items-center">
                      <PhotoProvider>
                        <div className="flex gap-6 flex-wrap items-center">
                          {gallery.map((img: any, index: number) => (
                            <PhotoView
                              key={index}
                              src={APP_STORAGE + img.gallery_pix_link}
                            >
                              <div
                                key={index}
                                className="relative border border-honeConnectPurple border-2 rounded-lg p-1 w-[45%]"
                              >
                                <img
                                  src={APP_STORAGE + img.gallery_pix_link}
                                  alt=""
                                  className="w-28 min-h-[100px] rounded-lg"
                                  style={{ width: "100%", cursor: "pointer" }}
                                />
                                <div className="absolute -top-3 -right-3 w-[30px] h-[30px] border-[3px] border-honeConnectPurple border-solid rounded-[50%] flex justify-center items-center bg-white">
                                  <span className="text-sm font-semibold text-honeConnectPurple">
                                    {index + 1}
                                  </span>
                                </div>
                              </div>
                            </PhotoView>
                          ))}
                        </div>
                      </PhotoProvider>

                      <div className="flex flex-col gap-1 hidden">
                        <div className="relative">
                          <a href="/home">
                            <img
                              src="img/pictureUpload.png"
                              alt=""
                              className="w-28"
                            />
                          </a>

                          {/* <!-- Number in circle --> */}
                          <div className="absolute -top-3 -right-3 w-[30px] h-[30px] border-[3px] border-honeConnectPurple border-solid rounded-[50%] flex justify-center items-center bg-white">
                            <span className="text-sm font-semibold text-honeConnectPurple">
                              2
                            </span>
                          </div>
                        </div>
                        <a
                          href="/home"
                          className="text-honeConnectOrange text-xs font-medium"
                        >
                          Upload Photo
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="text-left hidden">
                    <Link
                      to={SITELINKS.EDIT_PROFILE}
                      className="text-honeConnectPurple font-medium text-sm underline"
                    >
                      See all Photos
                    </Link>
                  </div>
                </div>
              </div>

              {myinfo.id === user.id && (
                <div className="bg-white dark:bg-slate-800 p-6 mt-2 sm:mb-0 rounded-b-[10px] pb-36">
                  <div className="flex flex-col gap-1 items-start sm:items-center">
                    <p className="text-sm font-medium text-honeConnectGray dark:text-gray-300">
                      Copy link
                    </p>
                    <div className="relative w-full">
                      <input
                        type="text"
                        className="w-full sm:w-2/4 bg-honeConnectGray50 dark:text-border-300 border border-solid border-honeConnectGray200 text-honeConnectGray200 dark:text-gray-300 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal text-xs dark:text-gray-300  dark:bg-slate-800"
                        value={"https://honeconnect.com/me/" + user?.username}
                        disabled
                      />

                      <button
                        onClick={() => {
                          copyToClipboard(
                            "https://honeconnect.com/me/" + user?.username
                          );
                        }}
                        className="absolute top-[3px] right-[3px]"
                      >
                        <TERipple className="bg-honeConnectPurple border dark:border-gray-300 dark:bg-slate-800 text-gray-100 p-2 rounded text-sm">
                          {copySuccess ? (
                            <div className="flex gap-1">Copied</div>
                          ) : (
                            <div className="flex gap-1">Copy</div>
                          )}
                        </TERipple>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {myinfo.id !== user.id && (
                <div className="bg-white dark:bg-slate-800 p-6 sm:mb-0 rounded-b-[10px] pb-36"></div>
              )}
            </div>

            <AdsBanner></AdsBanner>
          </div>
        </main>
      )}

      {/* <!-- Modal --> */}
      <TEModal show={showModal}>
        <TEModalDialog className="rounded-0">
          <TEModalContent className="h-[100vh] overflow-scroll rounded-t-none">
            <TEModalHeader className="bg-honeConnectPurple dark:border-slate-700 dark:bg-slate-800 text-white rounded-t-none">
              {/* <!--Modal title--> */}
              <h5 className="text-md font-normal leading-normal text-white">
                Verify your phone
              </h5>

              <Link
                to={SITELINKS.MY_RESPONSES}
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </Link>
            </TEModalHeader>

            {/* <!--Modal body--> */}
            <TEModalBody className="dark:bg-slate-800">
              <p className="text-xs mb-5 dark:text-gray-300">
                Phone verification is required for a connect!
              </p>

              <form autoComplete="off" autoCorrect="off" className="space-y-2">
                {/* Phone Number */}
                <div className="flex flex-col w-full mb-1">
                  <label
                    htmlFor="phone"
                    className="mb-1 text-sm font-medium  dark:text-gray-400"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country="ng"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                    }}
                    inputClass={`text-xs`}
                    inputProps={{ autoComplete: "off" }}
                  />
                </div>

                <div className="text-xs font-medium text-start mb-16 flex-col justify-center">
                  <span className="mb-3  dark:text-gray-400">
                    Phone correct?{" "}
                  </span>

                  {timeLeft > 0 ? (
                    <button
                      type="button"
                      className="text-honeConnectOrange text-xs font-medium text-center"
                    >
                      {formatTime(timeLeft)}
                    </button>
                  ) : (
                    <button
                      onClick={handleResend2FA}
                      type="button"
                      disabled={resendCode}
                      className="disabled:text-gray-400 disabled:cursor-not-allowed text-honeConnectPurple text-sm font-medium text-center dark:text-gray-200"
                    >
                      request OTP
                    </button>
                  )}
                </div>

                <div className="mt-5"></div>
                <div className="flex-col justify-center items-center w-full mt-5">
                  <label
                    htmlFor="phone"
                    className="flex-col mb-1 text-sm font-medium mt-5  dark:text-gray-400"
                  >
                    Enter OTP
                  </label>
                  <OTPInput
                    value={otp}
                    onChange={handleOTPChange}
                    numInputs={4}
                    shouldAutoFocus
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "0 12px 0 0",
                      fontSize: "20px",
                      borderRadius: 8,
                      border: "1px solid #6d7986",
                      background: "#f8f8f8",
                    }}
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="tel"
                        className="dark:text-slate-800"
                      />
                    )}
                  />
                </div>
              </form>
            </TEModalBody>

            <TEModalFooter className="flex gap-3 dark:bg-slate-800 dark:border-slate-700">
              {error && (
                <p className="flex-col w-full text-red-600 text-[10px] mb-1 xl:w-1/2 text-center">
                  {error}
                </p>
              )}

              <TERipple rippleColor="light" className="">
                <Link
                  to={SITELINKS.MY_RESPONSES}
                  type="button"
                  className="w-full text-red-700 inline-block rounded bg-red-100 px-6 py-3 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                >
                  Close
                </Link>
              </TERipple>

              <TERipple rippleColor="light" className="w-2/3">
                <button
                  disabled={otp.length !== 4 || loading}
                  onClick={handleVerification}
                  type="button"
                  className="disabled:bg-gray-400 w-full inline-block rounded px-6 py-3 text-sm font-medium uppercase text-gray-500 bg-honeConnectPurple  dark:bg-slate-800 border dark:border-slate-300 text-white shadow dark:text-gray-300"
                >
                  {loading ? "Verifying" : "Verify"}
                </button>
              </TERipple>
            </TEModalFooter>
          </TEModalContent>
        </TEModalDialog>
      </TEModal>

      <Preloader loading={loading} />
    </AppLayout>
  );
};

export default Profile;
